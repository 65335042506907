<template>
  <main>
    <section>
      <article class="about_us_article_one">
        <img src="../assets/images/TOK_4313.jpg">
        <div class="about_us_article_one_description">
          <div class="container">
            <div class="row">
              <div class="col-md-9">
                <h1>Experiences</h1>
                <p class="mt-4">It is our mission to enrich your life with unforgettable safari travel experiences</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_three pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_three_grid">
            <div>
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Ostriches</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  We believe that travel experiences are unforgettable because they open us to untried
                  experiences, new skills and different perspectives. Through travel and learning in an
                  enjoyable way, our lives are enriched, we meet new people, and we expand our potential.
                </p>
              </div>
            </div>
            <div>
              <img
                src="../assets/images/Kidepo-Valley-National-Park3.jpg"
                class="w-100"
              >
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_five pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_five_grid">
            <div>
              <img
                src="../assets/images/rothschild-giraffe-700x466-1.jpg"
                class="w-100"
              >
            </div>
            <div>
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Giraffes</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  All associates have been involved in the tourism business since 1994 working as Professional Tour
                  guides. Access Uganda Tours ensures you will always be in safe hands as we guide our trips
                  ourselves. This experience ensures our clients of an excellent and memorable trip.

                  We are active members of UGANDA SAFARI GUIDES ASSOCIATION and executive members of the UGANDA BIRD
                  GUIDES CLUB.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_three pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_three_grid">
            <div>
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Elephants</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  We believe that travel can be a positive influence in personal and community growth.
                  We consistently provide high-quality content, a superior user experience, and exceptional customer
                  care.
                  <br>
                  We offer you the security, efficiency, and peace-of-mind needed when booking
                  unforgettable holidays. Each travel experience is unique, and each individual involved
                  in creating that experience is unique. We embrace this diversity.
                </p>
              </div>
            </div>
            <div>
              <img
                src="../assets/images/TOK_4313.jpg"
                class="w-100"
              >
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_five pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_five_grid">
            <div>
              <img
                src="../assets/images/Karimojong.jpg"
                class="w-100"
              >
            </div>
            <div>
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Kidepo Community Walks</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Create your own dreams!</p>
                <p
                  style="color:black;"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Creating your own trip is the best way of getting everything out of your
                  holiday. The Access Uganda Tours staff is well known for being innovative and
                  creative. Use our specialism to create your own dream holiday!
                </p>
                <p
                  style="color:black;"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Please fill out the form below and you'll receive our offer and itinerary
                  for a quality trip. You may expect a response within 24 hours on workdays
                  (Monday-Friday).
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

  </main>
</template>

<script>
export default {};
</script>

<style scoped>
.about_us_article_one {
  background-color: #f0f9eb;
  height: 80vh;
  width: 100%;
  position: relative;
}

.about_us_article_one img {
  height: 80vh;
  width: 100%;
  object-fit: cover;
}

.about_us_article_one_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.about_us_article_one_description h1 {
  color: white;
  font-size: 2.2em;
  font-weight: 600;
}

.about_us_article_one_description p {
  color: white;
  font-size: 1.2em;
  font-weight: 500;
}

.about_us_article_three,
.about_us_article_five {
  background-color: #f0f9eb;
}

.about_us_article_three .about_us_article_three_grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
}

.about_us_article_three .about_us_article_three_grid > div {
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.about_us_article_three .about_us_article_three_grid img {
  border-radius: 5px;
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.about_us_article_three .about_us_article_three_grid h2 {
  color: black;
  font-size: 2em;
  font-weight: 600;
}

.about_us_article_three .about_us_article_three_grid p {
  color: grey;
  font-size: 1em;
}

.about_us_article_four {
  background-color: #ffffff;
}

.about_us_article_four .about_us_article_four_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.about_us_article_four .about_us_article_four_grid > div {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about_us_article_four .about_us_article_four_grid h2 {
  color: green;
  font-size: 2.8em;
  font-weight: 800;
}

.about_us_article_four .about_us_article_four_grid h3 {
  color: black;
  font-size: 1.2em;
  font-weight: 600;
}

.about_us_article_four .about_us_article_four_grid p {
  color: grey;
  font-size: 1em;
}

.about_us_article_five .about_us_article_five_grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}

.about_us_article_five .about_us_article_five_grid > div {
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.about_us_article_five .about_us_article_five_grid img {
  border-radius: 5px;
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.about_us_article_five .about_us_article_five_grid h2 {
  color: black;
  font-size: 2em;
  font-weight: 600;
}

.about_us_article_five .about_us_article_five_grid p {
  color: grey;
  font-size: 1em;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .about_us_article_three .about_us_article_three_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_three .about_us_article_three_grid > div:first-child {
    grid-auto-flow: row;
    grid-row: 2;
  }

  .about_us_article_three .about_us_article_three_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_three .about_us_article_three_grid p {
    font-size: 0.8em;
  }

  .about_us_article_five .about_us_article_five_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_five .about_us_article_five_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_five .about_us_article_five_grid p {
    font-size: 0.8em;
  }

  .about_us_article_four .about_us_article_four_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .about_us_article_four .about_us_article_four_grid h2 {
    font-size: 1.8em;
  }

  .about_us_article_four .about_us_article_four_grid h3 {
    font-size: 0.8em;
  }

  .about_us_article_four .about_us_article_four_grid p {
    display: none;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .about_us_article_three .about_us_article_three_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_three .about_us_article_three_grid > div:first-child {
    grid-auto-flow: row;
    grid-row: 2;
  }

  .about_us_article_three .about_us_article_three_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_three .about_us_article_three_grid p {
    font-size: 0.8em;
  }

  .about_us_article_five .about_us_article_five_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_five .about_us_article_five_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_five .about_us_article_five_grid p {
    font-size: 0.8em;
  }

  .about_us_article_four .about_us_article_four_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .about_us_article_four .about_us_article_four_grid h2 {
    font-size: 1.8em;
  }

  .about_us_article_four .about_us_article_four_grid h3 {
    font-size: 0.8em;
  }

  .about_us_article_four .about_us_article_four_grid p {
    display: none;
  }
}
</style>