<template>
  <div>
    <section>
      <article class="welcome_article">
        <div class="container">
          <div class="welcome_article_grid">
            <div>
              <div class="mb-4">
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >ADERE SAFARI LODGES</h2>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Embrace the magnificent Kidepo Valley Wilderness</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Adere Safari Lodge gorgeous safari lodge perched on the edge of Kidepo Valley National Park in a unique natural setting sitting on jagged and semi-arid rocks of Kidepo, that date back to over a millennia.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Adere Safari Lodge boasts 20 spacious en suite custom-designed safari cottages with functional Karamojong inspired touches, exclusive settings in the Wilderness of Kidepo Park to allow you get immersed in a truly African authentic experience unlike any other.
                </p>

                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Whether your dream is to enjoy a guided drive through the sweeping savannah of Kidepo Valley national Park, a sumptuous sunset dinner, a quiet and late night reading one of your favorite books by the fireplace, early morning coffee watching the tropical sun as it rises at the backdrop of the wilderness, a rewarding village walk or simply a leisurely walk around an accommodation facility, Adere Safari Lodge promises a memorable stay that will leave a mark to last forever.
                </p>
              </div>
            </div>

            <div>
              <!-- <img
                src="https://pixan-images.s3.amazonaws.com/2022-12-18T15%3A21%3A38.311Z-2.jpeg"
                alt=""
              > -->

              <img
                src="../../assets/big_images/restaurant.jpg"
                alt=""
              >

              <div class="welcome_description">
                <div>
                  <h3
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >Restaurant</h3>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    Our restaurant offers arguably the best views in the region. Serving you delicious meals through out your stay. We have a well stocked bar, a comfortable lounge complete with a cozy fireplace.
                  </p>
                </div>
                <div>
                  <h3
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >Nature</h3>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    Kidepo Valley’s savannah landscape is truly breathtaking. The beauty of the wilderness spreads beyond the valleys ahead towards horizons outlined by distant mountain ranges and seem to touch the sky. Whether by foot or in a safari vehicle explore the Narus valley, encounter the amazing wildlife and enjoy the journey through the wild frontier region of Karamoja.
                  </p>
                </div>
                <div>
                  <h3
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >Community Walks</h3>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    Discover the unique culture of the famous cattle- herding Kamajong tribe. Visit the Karamjong manyattas to share life experiences about the survival games in the wilderness and explore the authentic lifestyle of this indigenous African community who inspired Adere Safari Lodges décor choices
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="cottage_types">
        <div class="container text-center">
          <h2
            data-aos="fade-up"
            data-aos-duration="3000"
          >FEATURED COTTAGES</h2>
          <div>
            <div class="row mb-4">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Your private grass thatched chalets awaits you and yours</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Adere Safari Lodge provides 20 self-contained stand-alone chalet cottages that directly face the luxurious swimming pool stretching over this secluded wilderness.
                </p>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="cottage_types_grid">
              <div>
                <div>
                  <img
                    src="../../assets/big_images/cottages/double_room.jpg"
                    alt=""
                  >
                </div>
                <div class="cottage_type_text">
                  <div>
                    <p>Standard Cottages</p>
                    <el-button
                      class="btn-brown"
                      @click="$router.push({path: 'accommodation'})"
                    >Read More <span class="el-icon-d-arrow-right"></span></el-button>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/big_images/cottages/double_room1.jpg"
                    alt=""
                  >
                </div>
                <div class="cottage_type_text">
                  <div>
                    <p>Family Cottages</p>
                    <el-button
                      class="btn-brown"
                      @click="$router.push({path: 'accommodation'})"
                    >Read More <span class="el-icon-d-arrow-right"></span></el-button>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/big_images/cottages/double_room2.jpg"
                    alt=""
                  >
                </div>
                <div class="cottage_type_text">
                  <div>
                    <p>Honeymoon Cottages</p>
                    <el-button
                      class="btn-brown"
                      @click="$router.push({path: 'accommodation'})"
                    >Read More <span class="el-icon-d-arrow-right"></span></el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center pt-5">
              <el-button
                class="btn-view-more"
                @click="$router.push({path: 'accommodation'})"
              >View All Cottages <span class="el-icon-d-arrow-right"></span></el-button>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="ttc_activities">
        <h2
          class="text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >ADERE EXPERIENCES</h2>
        <div>
          <div class="row mb-4">
            <div class="col-md-2"></div>
            <div class="col-md-8 experiences_heading text-center">
              <h3
                data-aos="fade-up"
                data-aos-duration="3000"
              >Where your stay promises to be memorable</h3>
              <p
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Whether it is a drive through the vast savannah of Kidepo, a sunset dinner, an early morning watching of the sun as it rises, or a community visit and leisurely walkabout, may your time at Adere create beautiful memories. Enjoy a broad range of activities, and retire to your comfortable cottage at the end of it all, or perhaps go for a relaxing dip in the pool.
              </p>
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="ttc_activities_grid">
            <div class="ttc_activity_description d-flex align-items-center">
              <div>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Birding</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  The park boasts an extensive bird list of around 475 species, making it second only in Uganda to Queen Elizabeth National Park. A few species of note are the Ostrich, Kori Bustard and Karamoja Apalis.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Kidepo is notable for its birds of prey. Of the 56 species recorded, 14 – including Verreaux’s Eagle, Egyptian Vulture and Pygmy Falcon – are believed to be endemic to the Kidepo and Karamoja region.
                </p>
                <!-- <el-button
                  class="mt-2"
                  style="background-color: #513F03; color: white; width: 200px;"
                  @click="$router.push({path: '/accommodation'})"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  VIEW MORE
                </el-button> -->
              </div>
            </div>
            <div class="ttc_birding">
              <img src="../../assets/big_images/The_pool.jpg">
            </div>

            <div>
              <img src="../../assets/big_images/View_Tour.jpg">
            </div>
            <div class="ttc_activity_description d-flex align-items-center">
              <div>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Game Viewing in Kidepo Valley National Park</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  The park contains one of the most exciting faunas of any Ugandan national park. Along with the neighboring Karamoja region, it houses many species found nowhere else in Uganda, including the greater and lesser kudu, eland and cheetah. Carnivores here include the lion, leopard, spotted hyena and black-backed and side-striped jackals. Other large species regularly seen here are elephant, Burchell’s zebra, bushpig, warthog, Rothschild’s giraffe, Cape buffalo, bushbuck, bushduiker, Defassa waterbuck, Bohor reedbuck, Jackson’s hartebeest and oribi.
                </p>
                <!-- <el-button
                  class="mt-2"
                  style="background-color: #513F03; color: white; width: 200px;"
                  @click="$router.push({path: '/accommodation'})"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  VIEW MORE
                </el-button> -->
              </div>
            </div>

            <div class="ttc_activity_description d-flex align-items-center">
              <div>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Hiking/Nature Walks</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  The Lomej Mountains can be reached on foot in four hours, the hike starts at 7am. Shorter guided walks of around two hours can be taken through the Narus Valley extending over a 5km radius from Apoka Tourism Centre.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Visitors can also wander along the splendid Kidepo River Valley between banks of attractive borassus palm forest. Namamkweny Valley can be reached in one hour from Apoka. Visitors can also meet members of the IK tribe during prearranged hikes to the Morungole Mountains outside the park.
                </p>
                <!-- <el-button
                  class="mt-2"
                  style="background-color: #513F03; color: white; width: 200px;"
                  @click="$router.push({path: '/accommodation'})"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  VIEW MORE
                </el-button> -->
              </div>
            </div>
            <div class="ttc_trekking">
              <img src="../../assets/big_images/pool1.jpg">
            </div>

            <div>
              <img src="../../assets/big_images/Fire_Place.jpg">
            </div>
            <div class="ttc_activity_description d-flex align-items-center">
              <div>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Cultural Encounters</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Lorokul Cultural Group - The notorious, cattle-herding Karamojong occupy northeastern Uganda, in an area covering one tenth of the country.
                  Discover the unique culture of this remote tribe with the Lorukul Cultural Group, located just outside Kidepo Valley National Park.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Their main livelihood is herding livestock, and the social and cultural importance will be explained as you walk with the guides to the traditional Karamojong manyattas (homesteads), granaries and cattle enclosures.
                  Learn how the villagers make their distinctive beads, sample the local cuisine, and even meet the Karamojong King, who will narrate the tribe's folklore and beliefs.
                </p>
                <!-- <el-button
                  class="mt-2"
                  style="background-color: #513F03; color: white; width: 200px;"
                  @click="$router.push({path: '/accommodation'})"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  VIEW MORE
                </el-button> -->
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },
  },
};
</script>

<style scoped>
.welcome_article {
  background-color: #f9f7f3;
  padding-top: 150px;
  padding-bottom: 100px;
}
.welcome_article_grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.welcome_article_grid > div {
  min-height: 100vh;
}

.welcome_article_grid > div:first-child {
  padding-right: 100px;
  display: flex;
  align-items: center;
}

.welcome_article_grid > div:first-child h2 {
  color: #513f03;
  font-size: 1em;
  font-weight: 600;
}

.welcome_article_grid > div:first-child h3 {
  font-size: 2.5em;
  font-weight: 600;
}

.welcome_article_grid > div:first-child p {
  font-size: 1em;
  font-weight: 400;
}

.welcome_article_grid > div:nth-child(2) {
  position: relative;
}

.welcome_article_grid > div:nth-child(2) img {
  width: 50%;
  height: 100vh;
  object-fit: cover;
}

.welcome_article_grid > div:nth-child(2) .welcome_description {
  background-color: #513f03;
  color: #cbc2b0;
  position: absolute;
  padding: 50px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 50%;
}
.welcome_article_grid > div:nth-child(2) .welcome_description h3 {
  font-size: 1.2em;
}
.welcome_article_grid > div:nth-child(2) .welcome_description p {
  font-size: 1em;
  font-weight: 300;
}
.btn-brown {
  background-color: #3d290a;
  border: 2px solid #c2996c;
  border-radius: 10px;
  color: white;
  font-size: 0.8em;
  font-weight: 300;
}

.btn-view-more {
  background-color: #513f03;
  border: 2px solid #3d290a;
  border-radius: 10px;
  color: white;
  font-size: 1em;
  font-weight: 400;
  padding: 20px 40px;
}
.cottage_types {
  background-color: white;
  padding-top: 100px;
  padding-bottom: 100px;
}

.cottage_types h2 {
  font-size: 1em;
  font-weight: 400;
}

.cottage_types h3 {
  font-size: 2.5em;
  font-weight: 600;
}

.cottage_types p {
  font-size: 1em;
  font-weight: 400;
}

.cottage_types_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.cottage_types_grid > div {
  height: 500px;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.cottage_types_grid > div img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.cottage_types_grid > div:hover .cottage_type_text {
  height: 100%;
  padding-bottom: 50px;
}

.cottage_types_grid .cottage_type_text {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  color: white;
  font-size: 1.5em;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-radius: 10px;
  height: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: 0.5s ease;
}

.ttc_activities h2 {
  font-size: 1em;
  font-weight: 400;
}

.ttc_activities .experiences_heading h3 {
  font-size: 2.5em;
  font-weight: 600;
}

.ttc_activities .experiences_heading p {
  font-size: 1em;
  font-weight: 400;
}

.ttc_activities {
  background-color: #f4f2e4;
  padding: 100px 20px 20px 20px;
}

.ttc_activities_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.ttc_activities_grid > div {
  height: 80vh;
  background-color: #fffdfb;
}

.ttc_activities_grid > div img {
  height: 80vh;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.ttc_activities_grid > div:first-child {
  border-radius: 10px;
}
.ttc_activities_grid > div:nth-child(2) {
  border-radius: 10px;
}
.ttc_activities_grid > div:nth-child(2) img {
  border-radius: 10px;
}

.ttc_activities_grid > div:nth-child(3) {
  border-radius: 10px;
}
.ttc_activities_grid > div:nth-child(3) img {
  border-radius: 10px;
}
.ttc_activities_grid > div:nth-child(4) {
  border-radius: 10px;
}

.ttc_activities_grid > div:nth-child(5) {
  border-radius: 10px;
}
.ttc_activities_grid > div:nth-child(6) {
  border-radius: 10px;
}
.ttc_activities_grid > div:nth-child(6) img {
  border-radius: 10px;
}

.ttc_activities_grid > div:nth-child(7) {
  border-radius: 10px;
}
.ttc_activities_grid > div:nth-child(7) img {
  border-radius: 10px;
}
.ttc_activities_grid > div:nth-child(8) {
  border-radius: 10px;
}

.ttc_activities_grid .ttc_activity_description {
  padding: 50px;
}

.ttc_activities_grid > div h3 {
  font-size: 1.2em;
  font-weight: 500;
}

.ttc_activities_grid > div p {
  font-weight: 300;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .welcome_article_grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .welcome_article_grid > div:nth-child(2) {
    height: 125vh;
  }

  .welcome_article_grid > div:first-child {
    min-height: 100% !important;
    padding-top: 50px;
    padding-bottom: 20px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .welcome_article_grid > div:first-child h3 {
    font-size: 2em;
  }

  .welcome_article_grid > div:nth-child(2) img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }

  .welcome_article_grid > div:nth-child(2) .welcome_description {
    background-color: rgba(81, 63, 3, 0.9);
    color: #cbc2b0;
    position: absolute;
    padding: 20px;
    top: 30vh;
    bottom: 0px;
    right: 0px;
    width: 90%;
  }

  .cottage_types h3 {
    font-size: 2em;
  }

  .cottage_types_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .btn-brown {
    background-color: #d96b12;
    color: white;
    width: 100%;
    font-size: 0.7em;
  }

  .ttc_activities {
    background-color: #f4f2e4;
    padding: 100px 20px 20px 20px;
  }

  .ttc_activities_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .ttc_activities_grid > div:first-child {
    height: 100% !important;
  }
  .ttc_activities_grid > div:nth-child(2) {
    grid-auto-flow: column;
    grid-row: 1;
    height: 40vh;
  }
  .ttc_activities_grid > div:nth-child(3) {
    height: 40vh;
  }
  .ttc_activities_grid > div:nth-child(4) {
    height: 100% !important;
  }
  .ttc_activities_grid > div:nth-child(5) {
    height: 100% !important;
  }
  .ttc_activities_grid > div:nth-child(6) {
    grid-auto-flow: column;
    grid-row: 5;
    height: 40vh;
  }
  .ttc_activities_grid > div:nth-child(7) {
    height: 40vh;
  }
  .ttc_activities_grid > div:nth-child(8) {
    height: 100% !important;
  }
  .ttc_activities_grid > div img {
    height: 40vh;
  }
  .ttc_activities_grid .ttc_activity_description {
    padding: 20px;
  }

  .ttc_activities .experiences_heading h3 {
    font-size: 2em;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .welcome_article_grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .welcome_article_grid > div:nth-child(2) {
    height: 125vh;
  }

  .welcome_article_grid > div:first-child {
    min-height: 100% !important;
    padding-top: 50px;
    padding-bottom: 20px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .welcome_article_grid > div:first-child h3 {
    font-size: 2em;
  }

  .welcome_article_grid > div:nth-child(2) img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }

  .welcome_article_grid > div:nth-child(2) .welcome_description {
    background-color: rgba(81, 63, 3, 0.9);
    color: #cbc2b0;
    position: absolute;
    padding: 20px;
    top: 30vh;
    bottom: 0px;
    right: 0px;
    width: 90%;
  }

  .cottage_types h3 {
    font-size: 2em;
  }

  .cottage_types_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .cottage_types_grid > div {
    height: 400px;
    width: 100%;
    border-radius: 10px;
    position: relative;
  }

  .cottage_types_grid > div img {
    height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .btn-brown {
    background-color: #d96b12;
    color: white;
    width: 100%;
    font-size: 0.7em;
  }

  .ttc_activities {
    background-color: #f4f2e4;
    padding: 100px 20px 20px 20px;
  }

  .ttc_activities_grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .ttc_activities_grid > div:first-child {
    height: 100% !important;
  }
  .ttc_activities_grid > div:nth-child(2) {
    grid-auto-flow: column;
    grid-row: 1;
    height: 40vh;
  }
  .ttc_activities_grid > div:nth-child(3) {
    height: 40vh;
  }
  .ttc_activities_grid > div:nth-child(4) {
    height: 100% !important;
  }
  .ttc_activities_grid > div:nth-child(5) {
    height: 100% !important;
  }
  .ttc_activities_grid > div:nth-child(6) {
    grid-auto-flow: column;
    grid-row: 5;
    height: 40vh;
  }
  .ttc_activities_grid > div:nth-child(7) {
    height: 40vh;
  }
  .ttc_activities_grid > div:nth-child(8) {
    height: 100% !important;
  }
  .ttc_activities_grid > div img {
    height: 40vh;
  }
  .ttc_activities_grid .ttc_activity_description {
    padding: 20px;
  }

  .ttc_activities .experiences_heading h3 {
    font-size: 2em;
  }
}
</style>