<template>
  <div>
    <article class="theCarouselArticle">
      <el-carousel
        height="100vh"
        :interval="5000"
        arrow="always"
      >
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/bg1.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div class="text-center pb-5">
                      <h2>Adere Safari Lodges</h2>
                      <p>
                        We are committed to making your stay with us memorable for all the right reasons
                      </p>
                    </div>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/big_images/The_pool.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div class="text-center pb-5">
                      <h2>Adere Safari Lodges</h2>
                      <p>
                        We are committed to making your stay with us memorable for all the right reasons
                      </p>
                    </div>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/big_images/View_Tour.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8 pb-5">
                    <div class="text-center">
                      <h2>Adere Safari Lodges</h2>
                      <p>
                        Adere Bush breakfast and dinners when you visit during your honeymoon getaway or anniversary will be unmatched
                      </p>
                    </div>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/big_images/898A9756.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div class="text-center pb-5">
                      <h2>Adere Safari Lodges</h2>
                      <p>
                        Stay with us while discovering the secluded wilderness area found in the remote north-eastern side of Uganda.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

      <section>
        <div class="theCheckAvailabilitySection">
          <div>
            <div class="container">
              <div style="text-align: left">
                <div class="availabilityForm">
                  <div class="row">
                    <div class="col-6">
                      <label>Check-In</label><br />
                      <el-date-picker
                        v-model="checkInDate"
                        type="date"
                        placeholder="Check-In"
                        style="width: 100%;"
                      >
                      </el-date-picker>
                    </div>
                    <div class="col-6">
                      <label>Check-Out</label><br />
                      <el-date-picker
                        v-model="checkOutDate"
                        type="date"
                        placeholder="Check-Out"
                        style="width: 100%;"
                      >
                      </el-date-picker>
                    </div>
                  </div>

                  <div class="row peopleNo">
                    <div class="col-6">
                      <label>Adults</label><br />
                      <el-input-number
                        v-model="adults"
                        controls-position="right"
                        @change="handleChange"
                        :min="1"
                        :max="10"
                      ></el-input-number>
                    </div>
                    <div class="col-6">
                      <label>Children</label><br />
                      <div class="dropdown">
                        <el-input-number
                          v-model="children"
                          controls-position="right"
                          @change="handleChange"
                          :min="0"
                          :max="10"
                          class="dropbtn"
                        ></el-input-number>
                        <div class="dropdown-content">

                          <table class="">
                            <thead>
                              <tr>
                                <th>Child</th>
                                <th>Age</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="child in formattedChildren"
                                :key="child.childNum"
                              >
                                <td>{{ child.childNum }}</td>
                                <td>
                                  <input
                                    type="text"
                                    v-model="child.age"
                                    style="width: 100%"
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <el-button
                      class="checkBtn"
                      @click="checkAvailability"
                    >Check Availability <span class="el-icon-d-arrow-right"></span></el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      adults: 1,
      children: 0,
      formattedChildren: [],
      checkInDate: "",
      checkOutDate: "",
      checkAvailabilityObject: {},
    };
  },
  // computed: {
  //   formattedChildren() {
  //     return myArray.map((item) => {
  //       return {
  //         childNum: item,
  //         age: 3,
  //       };
  //     });
  //   },
  // },

  watch: {
    children: {
      handler() {
        const myArray = [];
        for (let i = 1; i <= this.children; i++) {
          myArray.push(i);
        }

        this.formattedChildren = myArray.map((item) => {
          return {
            childNum: item,
            age: "",
          };
        });
      },
    },
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },

    checkAvailability() {
      if (this.checkInDate === "") {
        return this.$notify({
          title: "Empty",
          message: "Check In Date is Empty",
          type: "warning",
        });
      }
      if (this.checkOutDate === "") {
        return this.$notify({
          title: "Empty",
          message: "Check Out Date is Empty",
          type: "warning",
        });
      }
      this.checkAvailabilityObject = {
        checkInDate: this.checkInDate,
        checkOutDate: this.checkOutDate,
        adults: this.adults,
        children: this.children,
        children_ages: this.formattedChildren,
      };

      // alert(this.checkAvailabilityObject);
      // alert(JSON.stringify(this.checkAvailabilityObject));

      // Put the object into storage
      localStorage.setItem(
        "checkAvailabilityObject",
        JSON.stringify(this.checkAvailabilityObject)
      );
      // Retrieve the object from storage
      var retrievedObject = localStorage.getItem("checkAvailabilityObject");
      console.log("Retrieved Object: ", JSON.parse(retrievedObject));

      this.$router.push({ path: "accommodation" });
      // formattedChildren
    },
  },
};
</script>

<style scoped>
.locus_word_style {
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel_description {
  position: relative;
  height: 100vh;
}
.carousel_description img {
  height: 100vh;
  object-fit: cover;
}

.carousel_description .image-description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  /* margin-left:10%;
    margin-right:10%; */
  padding-bottom: 10%;
  background-image: linear-gradient(
    to bottom left,
    rgba(255, 255, 255, 0),
    rgba(61, 41, 10, 0.5)
  );
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.carousel_description .image-description h2 {
  font-size: 2em;
  font-weight: 600;
  color: white;
}

.carousel_description .image-description p {
  font-size: 1.2em;
  font-weight: 300;
  color: white;
}

.moreBtn {
  background-color: transparent;
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.moreBtn:hover {
  background-color: rgba(61, 41, 10, 0.5);
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.theCarouselArticle {
  height: 100vh;
  /* background-color: #EEEEEE; */
  background-image: linear-gradient(to right, #f4dcb7, #fbf2e1, #f4dcb7);
  position: relative;
}

.theCheckAvailabilitySection {
  width: 100%;
  position: absolute;
  bottom: -50px;
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: center;
}

.theCheckAvailabilitySection > div {
  width: 90%;
  background-color: rgba(191, 147, 84, 0.8);
  /* background-color: #bf9354; */
  color: #fbf2e1;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px 10px 10px 10px;
}

.availabilityForm {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.availabilityForm > div {
  font-size: 0.8em;
}

.checkBtn {
  background-color: #513f03;
  border: 2px solid #513f03;
  color: #fbf2e1;
  border-radius: 0px;
  text-transform: uppercase;
}

.childrenArticle {
  position: relative;
}
.childrenAges {
  background-color: #eee;
  color: black;
  position: absolute;
  bottom: -500px;
  width: 100%;
  min-height: 500px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: black;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

/**** ****************************************************888*** */
.dropbtn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #dddddd;
  /* background-color: #f9f9f9; */
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  /* background-color: #f1f1f1; */
}

.dropdown:hover .dropdown-content {
  display: block;
}
/**** ****************************************************888*** */

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .availabilityForm {
    display: block;
  }

  .checkBtn {
    width: 100%;
  }

  .theCheckAvailabilitySection {
    position: absolute;
    bottom: -100px;
  }

  .carousel_description .image-description {
    padding-bottom: 55%;
  }

  .peopleNo {
    margin-top: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .availabilityForm {
    display: block;
  }

  .checkBtn {
    width: 100%;
  }

  .theCheckAvailabilitySection {
    position: absolute;
    bottom: -100px;
  }

  .carousel_description .image-description {
    padding-bottom: 55%;
  }

  .peopleNo {
    margin-top: 20px;
  }
}
</style>

<style>
.input-class .el-input__inner {
  width: 50px !important;
}

.el-date-editor .el-range-input {
  /* width: 20% !important; */
}

.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 100% !important;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    margin-bottom: 20px;
  }

  .el-input-number.is-controls-right {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
