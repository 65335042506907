<template>
  <div>
    <section>
      <article class="contact_header">
        <div class="container h-100 d-flex align-items-end text-white">
          <div class="pb-3">
            <h1>Contact Us</h1>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item>Contact Us</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="contact_body pt-5 pb-5">
        <div class="container">
          <div>
            <h2 class="text-center">How can we help you?</h2>
            <div class="w-100 d-flex align-items-center justify-content-center">
              <div style="width: 200px;">
                <el-divider><i class="el-icon-sunny"></i></el-divider>
              </div>
            </div>
          </div>

          <section>
            <article>
              <div class="help_grid">
                <div>
                  <el-card
                    shadow="hover"
                    class="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div class="text-center contact-content">
                      <h3 class="m-0 p-0">Address</h3>
                      <span>karenga, Kidepo-Gulu, park</span>
                    </div>
                  </el-card>
                </div>
                <div>
                  <el-card
                    shadow="hover"
                    class="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div class="text-center contact-content">
                      <h3 class="m-0 p-0">Phone Number</h3>
                      <span>+256706 269726</span>
                    </div>
                  </el-card>
                </div>
                <div>
                  <el-card
                    shadow="hover"
                    class="h-100 d-flex align-items-center justify-content-center"
                  >
                    <div class="text-center contact-content">
                      <h3 class="m-0 p-0">Email Address</h3>
                      <span>info@aderesafarilodges.com</span>
                    </div>
                  </el-card>
                </div>
              </div>
            </article>
          </section>

          <section>
            <article>
              <div class="contact_form">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <el-form
                      :model="contactForm"
                      :rules="rules"
                      ref="contactForm"
                      label-width="120px"
                      class="demo-contactForm"
                      label-position="top"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item prop="first_name">
                            <el-input
                              v-model="contactForm.first_name"
                              placeholder="First Name"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item prop="last_name">
                            <el-input
                              v-model="contactForm.last_name"
                              placeholder="Last Name"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item prop="email">
                            <el-input
                              v-model="contactForm.email"
                              placeholder="Email"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item prop="phone">
                            <el-input
                              v-model="contactForm.phone"
                              placeholder="Phone"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <el-form-item prop="message">
                            <el-input
                              type="textarea"
                              v-model="contactForm.message"
                              placeholder="Message..."
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="text-center">
                        <el-form-item class="m-0">
                          <el-button
                            style="background-color: #513F03; color: white; width: 200px;"
                            @click="submitForm('contactForm')"
                          >Submit</el-button>
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </article>
          </section>

          <section>
            <article class="container mt-5">
              <div>
                <h2 class="text-center">How to get there.</h2>
                <div class="w-100 d-flex align-items-center justify-content-center">
                  <div style="width: 200px;">
                    <el-divider><i class="el-icon-sunny"></i></el-divider>
                  </div>
                </div>
                <div>
                  <p></p>
                  <p></p>
                  <p>

                  </p>
                  <p>

                  </p>
                  <p>

                  </p>
                  <p>

                  </p>

                  <ol class="gradient-list">
                    <li>Get on Kampala - Northern Bypass Highway</li>
                    <li>Follow Kampala - Gulu Hwy, Kamdini - Lira and Lira-Kitgum Rd to Northern Region (approx. 388 km)</li>
                    <li>Continue to Pader Palwo – Moroto (Approx. 43.0 km)</li>
                    <li>Turn right onto Pader Palwo - Moroto (Approx. 6.1 km)</li>
                    <li>Drive to Kidepo-Gulu in Karenga (Approx. 123 km)</li>
                    <li>Follow the Kidepo Valley National Park signs and Adere Safari Lodge signs until you arrive at Adere Safari Lodges made gate</li>
                  </ol>

                </div>
              </div>
            </article>
            <article>
              <div class="contact-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d2041616.6631314405!2d31.53252800851068!3d1.9395391984452364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d0.30599499999999996!2d32.5627272!4m5!1s0x1774f9a9541df8c7%3A0xbd7a37388cb6afed!2sadere%20safari%20lodge!3m2!1d3.5742851!2d33.696283199999996!5e0!3m2!1sen!2sug!4v1665326271622!5m2!1sen!2sug"
                  width="100%"
                  height="500"
                  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
              </div>
            </article>
          </section>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactForm: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
      },
      rules: {
        first_name: [
          {
            required: true,
            message: "Please input First Name",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Please input Last Name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please input Email",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "Please input Phone",
            trigger: "change",
          },
        ],
        message: [
          {
            required: true,
            message: "Please input Message",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Lato", sans-serif;
}

.contact_header {
  height: 40vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url("../assets/images/bg1.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact_header h1 {
  font-size: 1.2em;
}

.contact_body {
  background-color: #f7f7f7;
}

.contact_body .help_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.contact_body .help_grid > div {
  height: 180px;
}

.contact_body .help_grid .contact-content span {
  font-weight: 300;
  font-size: 0.8em;
}

.contact_form {
  background-color: white;
  margin-top: 20px;
  padding: 50px 50px;
}

.contact-map {
  background-color: white;
  margin-top: 20px;
  margin-bottom: 50px;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-weight: 300;
}

h3 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 15px;
}

/* box-shadow */
ol.gradient-list > li::before,
ol.gradient-list > li {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}

/*** STYLE ***/
*,
*:before,
*:after {
  box-sizing: border-box;
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
}
ol.gradient-list > li {
  background: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  margin-top: 1rem;
  min-height: 3rem;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
}
ol.gradient-list > li::before,
ol.gradient-list > li::after {
  background: linear-gradient(135deg, #83e4e2 0%, #a2ed56 100%);
  border-radius: 1rem 1rem 0 1rem;
  content: "";
  height: 3rem;
  left: -1rem;
  overflow: hidden;
  position: absolute;
  top: -1rem;
  width: 3rem;
}
ol.gradient-list > li::before {
  align-items: flex-end;
  content: counter(gradient-counter);
  color: #1d1f20;
  display: flex;
  font: 900 1.5em/1 "Montserrat";
  justify-content: flex-end;
  padding: 0.125em 0.25em;
  z-index: 1;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .contact_body .help_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .contact_body .help_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
</style>

<style>
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-size: 0.8em;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: grey;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-size: 0.8em;
  font-weight: 400;
  color: #cfd1d6;
  cursor: text;
}
</style>
